<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  import RepositoryProvider from './providers/RepositoryProvider'

  export default {
    provide: RepositoryProvider,
  }
</script>

<style lang="scss" scoped>
</style>
