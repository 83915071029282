import ServiceNotificationRepositoryInterface from '../repositories/interfaces/ServiceNotificationRepositoryInterface'
import PatientBedRepositoryInterface from '../repositories/interfaces/PatientBedRepositoryInterface'
import BedBlockingRepositoryInterface from '../repositories/interfaces/BedBlockingRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	serviceNotificationRepository: bind('ServiceNotificationRepository', ServiceNotificationRepositoryInterface),
	patientBedRepository: bind('PatientBedRepository', PatientBedRepositoryInterface),
	bedBlockingRepository: bind('BedBlockingRepository', BedBlockingRepositoryInterface),
}
