export default {
	getAll(customer_id) {},
	find(id) {},
	save(customer_id, id, data) {},
	delete(customer_id, id) {},
	update(id, data) {},
	getAllCountry() {},
	getAllDepartament() {},
	getAllCitiesByDepartament(id) {},
}
