import ClientRepositoryInterface from '../repositories/interfaces/ClientRepositoryInterface'
import UserRepositoryInterface from '../repositories/interfaces/UserRepositoryInterface'
import HeadquarterRepositoryInterface from '../repositories/interfaces/HeadquarterRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	clientRepository: bind(
		'ClientRepository',
		ClientRepositoryInterface,
	),
	userRepository: bind(
		'UserRepository',
		UserRepositoryInterface,
	),
	headquarterRepository: bind(
		'HeadquarterRepository',
		HeadquarterRepositoryInterface,
	),
}
