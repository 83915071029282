import CompaniesRepositoryInterface from '../repositories/interfaces/CompaniesRepositoryInterface'
import HeadquarterRepositoryInterface from '../repositories/interfaces/HeadquarterRepositoryInterface'
import ServicesRepositoryInterface from '../repositories/interfaces/ServicesRepositoryInterface'
import AreasRepositoryInterface from '../repositories/interfaces/AreasRepositoryInterface'
import SubAreasRepositoryInterface from '../repositories/interfaces/SubAreasRepositoryInterface'
import LocationsRepositoryInterface from '../repositories/interfaces/LocationsRepositoryInterface'
import DevicesRepositoryInterface from '../repositories/interfaces/DevicesRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	companiesRepository: bind('CompaniesRepository', CompaniesRepositoryInterface),
	headquarterRepository: bind('HeadquarterRepository', HeadquarterRepositoryInterface),
	servicesRepository: bind('ServicesRepository', ServicesRepositoryInterface),
	areasRepository: bind('AreasRepository', AreasRepositoryInterface),
	subAreasRepository: bind('SubAreasRepository', SubAreasRepositoryInterface),
	locationsRepository: bind('LocationsRepository', LocationsRepositoryInterface),
	devicesRepository: bind('DevicesRepository', DevicesRepositoryInterface),
}
