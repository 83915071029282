export default {
	getAll() {},
	find(id) {},
	save(id, data) {},
	delete(id) {},
	update(id, data) {},
	getAllCountry() {},
	getAllDepartament() {},
	getAllCitiesByDepartament(id) {},
}
